<template>
	<router-view style="padding:12px"/>
</template>

<script>
	import caches from '@cache/cache.js';
	export default {
		components: {},
		data() {
			return {};
		},
		created() {
			let menu = caches.topMenu.get()
			menu.forEach(item=>{
				if(item.url===this.$route.name){
					this.$router.replace({
						name: item.child[0].child[0].url
					});
					return
				}
			})
		},
		methods: {},
	};
</script>

<style lang='scss' scoped>
	.content {
		margin: 12px;
	}

	.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}

	.outerMenu.el-menu-item-group .el-menu-item-group__title {
		padding: 0 !important;
	}
</style>
